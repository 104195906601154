<template>
  <div class="mobile-container">
    <div class="bg-item img-cover">
      <img src="../../assets/images/watermark-bg.jpg" />
      <div class="back-icon img-cover" @click="$router.go(-1)">
        <img src="../../assets/images/back-icon-white.png" />
      </div>
    </div>
    <div class="page-content">
      <div class="input-item">
        <div class="input"><input ref="urlInput" v-model="url" type="text" placeholder="请复制视频或图集链接" /></div>
        <div v-if="url" class="btn blue" @click="handleSubmit">
          <van-loading v-if="loading" color="#ffffff" />
          <span v-else>去水印</span>
        </div>
        <div v-else class="btn blue" @click="handlePaste">粘贴</div>
        <div class="btn" @click="handleClear">清空</div>
      </div>
      <div v-if="assetsData.list && assetsData.list.length > 0" class="assets-item">
        <div class="header-item">
          <div class="left-item" @click="handleSelectedAll">
            <div class="icon img-cover">
              <img v-if="assetsData.all" src="../../assets/images/selected-icon.png" />
              <img v-else src="../../assets/images/not-selected-icon.png" />
            </div>
            全选
          </div>
          <div class="count">已选中{{ selectedList.length }}张图片</div>
        </div>
        <div class="img-list-item">
          <div class="list-item">
            <div v-for="(item, index) in assetsData.list" :key="index" class="img-item img-cover">
              <img :src="item" />
              <div class="icon img-cover" @click="handleSelected(item)">
                <img v-if="selectedList.includes(item)" src="../../assets/images/selected-icon.png" />
                <img v-else src="../../assets/images/not-selected-icon.png" />
              </div>
              <div class="copy-item" @click="handleCopy(item)">复制链接</div>
            </div>
          </div>
        </div>
        <div class="save-item public-btn" @click="handleSaveAllImgs">全部保存至相册</div>
      </div>
      <div v-if="assetsData.videoUrl" class="assets-item">
        <div class="video-item img-cover">
          <img :src="assetsData.videoImg" />
        </div>
        <div class="btn-item">
          <div class="btn blue" @click="handleCopy(assetsData.videoUrl)">复制无水印链接</div>
          <div class="btn blue1" @click="handleCopy(assetsData.videoImg)">复制封面链接</div>
          <div class="btn yellow" @click="handleDownloadAssets(assetsData.videoUrl)">保存到手机</div>
          <div class="btn yellow" @click="handleDownloadAssets(assetsData.videoImg)">保存封面</div>
          <div class="btn blue" @click="handleDownloadAssets(assetsData.videoUrl)">下载通道二</div>
          <div class="btn blue1" @click="handleCopy(assetsData.title)">复制视频标题</div>
        </div>
        <div class="flex-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import download from 'downloadjs'

export default {
  name: "Watermark",
  data() {
    return {
      url: '',
      loading: false,
      assetsData: {
        all: false, // 全选
        list: [], // 说明是图片
        videoImg: '', // 视频封面
        videoUrl: '', // 视频下载地址
        title: '' // 标题
      },
      selectedList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    
  },
  methods: {
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    handleClear() {
      this.url = ''
    },
    handlePaste() {
      if (navigator.clipboard) {
        navigator.clipboard.readText().then(text => {
          if (text) {
            this.url = text
          }
        })
      } else {
        this.$toast('粘贴失败')
      }
    },
    async handleGetDouYinMasterUrl(data) {
      var res = this.$API.getDouYinMasterUrl.post({
        Url: data
      })
      return res
    },
    handleSelected(img) {
      const { selectedList } = this
      const list = []
      let has = false
      for (let index = 0; index < selectedList.length; index++) {
        const item = selectedList[index]
        if (item == img) {
          has = true
        } else {
          list.push(item)
        }
      }
      if (!has) {
        list.push(img)
      }
      this.selectedList = list
    },
    handleSelectedAll() {
      const { assetsData } = this
      if (assetsData.all) {
        this.assetsData.all = false
        this.selectedList = []
      } else {
        this.assetsData.all = true
        this.selectedList = [
          ...assetsData.list
        ]
      }
    },
    handleSaveAllImgs() {
      const { selectedList } = this
      if (selectedList.length > 0) {
        for (let index = 0; index < selectedList.length; index++) {
          const item = selectedList[index]
          this.handleDownloadAssets(item)
        }
      }
    },
    handleDownloadAssets(url) {
      download(url)
    },
    async handleSubmit() {
      const { url, loading } = this
      if (loading) {
        return
      }
      this.loading = true
      var urlRes = await this.handleGetDouYinMasterUrl(url)
      if (urlRes.error != 0) {
        this.loading = false
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: urlRes.info,
          confirmButtonText: '确定',
        })
        return
      }
      const resUrl = urlRes.info
      var res = await this.$API.tsy.post({
        Url: resUrl
      })
      this.loading = false
      const data = JSON.parse(res.info)
      this.selectedList = []
      if (data.code == 200) {
        const list = data.data.pics || data.data.images || []
        const videoImg = data.data.cover
        const videoUrl = data.data.down
        this.assetsData = {
          all: false,
          list,
          videoImg,
          videoUrl,
          title: data.data.title
        }
      } else {
        this.assetsData = {
          all: false,
          list: [],
          videoImg: '',
          videoUrl: '',
          title: ''
        }
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: data.msg,
          confirmButtonText: '确定',
        })
      }
    },
  }
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bg-item {
  width: 100%;
  height: 4.8rem;
  position: relative;
  .back-icon {
    position: absolute;
    left: .24rem;
    top: .14rem;
    width: .6rem;
    height: .6rem;
  }
}
.page-content {
  flex: 1;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  border-radius: .24rem .24rem 0 0;
  margin-top: -.4rem;
  padding: .32rem .32rem .24rem .32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .input-item {
    width: 100%;
    height: .88rem;
    box-sizing: border-box;
    padding: 0 .12rem 0 .24rem;
    background-color: #f7f7f7;
    border-radius: .16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input {
      flex: 1;
      height: .64rem;
    }
    .btn {
      width: 1.2rem;
      height: .64rem;
      margin-left: .2rem;
      text-align: center;
      line-height: .64rem;
      border-radius: .08rem;
      font-size: .26rem;
      font-weight: 500;
      color: #276eff;
      background-color: #fff;
      &.blue {
        color: #fff;
        background: linear-gradient( 135deg, #58b7ff 0%, #276eff 100%);
      }
    }
  }
  .assets-item {
    width: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .header-item {
    width: 100%;
    height: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .28rem;
    .left-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #242941;
      .icon {
        width: .36rem;
        height: .36rem;
        margin-right: .12rem;
      }
    }
    .count {
      color: #b3b3b3;
    }
  }
  .img-list-item {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .img-item {
      width: 3.28rem;
      height: 4.2rem;
      border-radius: .16rem;
      overflow: hidden;
      position: relative;
      margin-bottom: .24rem;
      .icon {
        position: absolute;
        top: .12rem;
        right: .04rem;
        width: .36rem;
        height: .36rem;
        z-index: 1;
      }
      .copy-item {
        position: absolute;
        z-index: 1;
        left: .04rem;
        right: .04rem;
        bottom: .04rem;
        height: .72rem;
        border-radius: .16rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .28rem;
        color: #fff;
        // filter: blur(5px);
        background-color: rgba(0, 0, 0, .8);
      }
    }
  }
  .save-item {
    margin-top: .24rem;
    width: 100%;
    height: .88rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: .28rem;
    font-weight: bold;
  }
  .video-item {
    border: .1rem solid #000;
    border-radius: .2rem;
    overflow: hidden;
    width: 100%;
    height: 3.6rem;
    box-sizing: border-box;
    margin-top: .24rem;
  }
  .btn-item {
    margin-top: .24rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      width: 2.12rem;
      height: .72rem;
      border-radius: .12rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: .24rem;
      font-weight: 500;
      margin-bottom: .24rem;
      &.blue {
        background-color: #276eff;
      }
      &.blue1 {
        background-color: #58b7ff;
      }
      &.yellow {
        background-color: #ffa058;
      }
    }
  }
}
</style>
